import React from 'react';
import { Routes, Route } from 'react-router-dom'; // Menggunakan Routes dan Route dari react-router-dom untuk menangani navigasi
import Sidebar from '../adminComponents/Sidebar'; // Impor Sidebar
import Gallery from '../adminComponents/Gallery';
import Header from '../adminComponents/Header';
import Features from '../adminComponents/Features';
import About from '../adminComponents/About';
import Team from '../adminComponents/Team';
import Services from '../adminComponents/Services';
import Testimonial from '../adminComponents/Testimonial';
import AboutDetail from '../adminComponents/Aboutdetail';
import GalleryHeader from '../adminComponents/GalleryHeader';
import TestimonialHeader from '../adminComponents/TestimonialHeader';
import Contact from '../adminComponents/Contact';
import Memo from '../adminComponents/Memo';
import Blog from '../adminComponents/Blog';
import UpdateUser from '../adminComponents/User';

// Impor CSS Module
import styles from './homeadmin.module.css';

const HomeAdmin = () => {
  return (
    <div className={styles.homeAdmin}>
      {/* Sidebar tetap di kiri */}
      <Sidebar />

      <div className={styles.mainContent}>
        {/* Routes untuk menampilkan konten dinamis */}
        <Routes>
          <Route path="gallery" element={<Gallery />} />
          <Route path="header" element={<Header />} />
          <Route path="features" element={<Features />} />
          <Route path="about" element={<About />} />
          <Route path="team" element={<Team />} />
          <Route path="Services" element={<Services />} />
          <Route path="Testimonial" element={<Testimonial />} />
          <Route path="AboutDetail" element={<AboutDetail />} />
          <Route path="galleryheader" element={<GalleryHeader />} />
          <Route path="TestimonialHeader" element={<TestimonialHeader />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="Memo" element={<Memo />} />
          <Route path="Blog" element={<Blog />} />
          <Route path="UpdateUser" element={<UpdateUser />} />
          {/* Default route bisa menuju halaman lain atau halaman default */}
          <Route path="/" element={<Gallery />} /> {/* Ganti dengan konten default jika diperlukan */}
        </Routes>
      </div>
    </div>
  );
};

export default HomeAdmin;
