import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './css/UserList.module.css';  // Import Modul CSS

const UserList = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirmPassword: ''
  });
  const [editingUserId, setEditingUserId] = useState(null);
  const [message, setMessage] = useState('');

  // Load daftar pengguna saat komponen dimuat
  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setUsers(data);  // Set data jika array
        } else {
          console.error('Data yang diterima bukan array:', data);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { username, password, confirmPassword } = formData;

    // Validasi password jika diubah
    if (password !== confirmPassword) {
      setMessage("Passwords don't match");
      return;
    }

    const method = editingUserId ? 'PUT' : 'POST';
    const url = editingUserId 
      ? `${process.env.REACT_APP_API_URL}/api/auth/update/${editingUserId}` 
      : `${process.env.REACT_APP_API_URL}/api/auth/register`;

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage(data.message);
        loadUsers();  // Refresh data pengguna setelah submit
        setFormData({ username: '', password: '', confirmPassword: '' });
        setEditingUserId(null);  // Reset state editing
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (id) => {
    const userToEdit = users.find((user) => user.id === id);
    setFormData({ username: userToEdit.username, password: '', confirmPassword: '' });
    setEditingUserId(id);
  };

  const handleDelete = (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this user?");
    if (isConfirmed) {
      fetch(`${process.env.REACT_APP_API_URL}/api/auth/users/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          setUsers(users.filter((user) => user.id !== id));
          alert('User deleted successfully');
        })
        .catch((error) => {
          console.log(error);
          alert('Error deleting user');
        });
    }
  };

  return (
    <div className={styles.container}>
      <h1>User List</h1>

      {/* Form untuk Create/Update User */}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
        </div>
        <button type="submit">{editingUserId ? 'Update' : 'Create'} User</button>
      </form>

      {message && <p>{message}</p>}

      {/* Daftar Pengguna */}
      <h2>Existing Users</h2>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            <span>{user.username}</span>
            <div>
              <button style={{backgroundColor:'#45a049'}} onClick={() => handleEdit(user.id)}>Edit</button>
              <button onClick={() => handleDelete(user.id)}>Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserList;
