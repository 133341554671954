import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './css/Sidebar.module.css';  // Import CSS module

const Sidebar = () => {
  const handleDelete = ()=> {
    localStorage.removeItem("authToken");
    window.location.href = '/admin';
  }
  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarHeader}>
        <h2><span className={styles.adminText}>Admin </span>
          <span className={styles.dashboardText}>Dashboard</span>
        </h2>
        <button style={{backgroundColor:'red'}} onClick={() => handleDelete()}>Log out</button>
      </div>
      <br/>
      <div className={styles.sidebarLinks}>
        <ul>
          <li><Link to="/HomeAdmin/features" className={styles.sidebarLink}>Fitur Kami</Link></li>
          
          {/* About Menu dengan Dropdown */}
          <li className={styles.dropdown}>
            <a href="#" className={styles.sidebarLink}>Kenapa Kami <span className={styles.arrow}>→</span></a>
            <ul className={styles.subMenu}>
              <li><Link to="/HomeAdmin/header" className={styles.sidebarSubLink}> Header</Link></li>
              <li><Link to="/HomeAdmin/aboutdetail" className={styles.sidebarSubLink}> Detail</Link></li>
            </ul>
          </li>

          {/* Portofolio Menu dengan Dropdown */}
          <li className={styles.dropdown}>
            <a href="#" className={styles.sidebarLink}>Portofolio <span className={styles.arrow}>→</span></a>
            <ul className={styles.subMenu}>
              <li><Link to="/HomeAdmin/galleryheader" className={styles.sidebarSubLink}> Header</Link></li>
              <li><Link to="/HomeAdmin/gallery" className={styles.sidebarSubLink}> Detail</Link></li>
            </ul>
          </li>

          {/* Client List Menu dengan Dropdown */}
          <li className={styles.dropdown}>
            <a href="#" className={styles.sidebarLink}>Client List <span className={styles.arrow}>→</span></a>
            <ul className={styles.subMenu}>
              <li><Link to="/HomeAdmin/TestimonialHeader" className={styles.sidebarSubLink}> Header</Link></li>
              <li><Link to="/HomeAdmin/Testimonial" className={styles.sidebarSubLink}> Detail</Link></li>
            </ul>
          </li>

          <li><Link to="/HomeAdmin/Services" className={styles.sidebarLink}>servis</Link></li>
          <li><Link to="/HomeAdmin/team" className={styles.sidebarLink}>Team</Link></li>
          <li><Link to="/HomeAdmin/Contact" className={styles.sidebarLink}>kontak</Link></li>
          <li><Link to="/HomeAdmin/Blog" className={styles.sidebarLink}>Blog konten</Link></li>
          <li><Link to="/HomeAdmin/Memo" className={styles.sidebarLink}>Penawaran list</Link></li>
          <li><Link to="/HomeAdmin/UpdateUser" className={styles.sidebarLink}>User list</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
